import React, { useState, useEffect } from "react";
import { NavLink, Outlet } from "react-router-dom";
import logo from "../images/full_hd_transparent_logo_white2.png";
import FooterContent from "../components/FooterContent";
import Login from "../components/Login";
import Signup from "../components/Signup";
import ForgotPassword from "../components/ForgotPassword";
import Modal from "../components/Modal";
import { signOut, getCurrentUser, fetchAuthSession, fetchUserAttributes } from 'aws-amplify/auth';
import { Hub } from 'aws-amplify/utils';
import { useUser } from "../contexts/UserContext";
import { useLocation } from "react-router-dom";

const RootLayout = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showLogin, setShowLogin] = useState(true);
  const [showForgotPassword, setShowForgotPassword] = useState(false);
  const [user, setUser] = useState(null);
  const [isAdmin, setIsAdmin] = useState(false);
  const [showAbout, setShowAbout] = useState(false);
  const [showUserMenu, setShowUserMenu] = useState(false);
  const [showAdminMenu, setShowAdminMenu] = useState(false);
  const [isDev, setIsDev] = useState(false);
  const location = useLocation();
  const { userGroups } = useUser();

 


  const openModal = () => setIsModalOpen(true);
  const closeModal = () => {
    setIsModalOpen(false);
    setShowLogin(true);
    setShowForgotPassword(false);
  };
  const showSignup = () => {
    setShowLogin(false);
    setShowForgotPassword(false);
  };
  const showLoginScreen = () => {
    setShowLogin(true);
    setShowForgotPassword(false);
  };
  const showForgotPasswordScreen = () => {
    setShowForgotPassword(true);
    setShowLogin(false);
  };

  const signOutUser = async () => {
    try {
      await signOut();
      setUser(null);
      setIsAdmin(false);
    } catch (error) {
      console.error("Error signing out:", error);
    }
  };

  const fetchUser = async () => {
    try {
      const currentUser = await getCurrentUser();
      if (currentUser) {
        const userAttributes = await fetchUserAttributes();
  
        const session = await fetchAuthSession();
        const idTokenPayload = session.tokens?.idToken?.payload;
        
        if (idTokenPayload) {
          const groups = idTokenPayload['cognito:groups'] || [];
          setUser({ ...currentUser, attributes: userAttributes });
          setIsAdmin(groups.includes('admin'));
        } else {
          setUser(null);
          setIsAdmin(false);
        }
      } else {
        setUser(null);
        setIsAdmin(false);
      }
    } catch (error) {
      console.error('Error fetching user:', error);
      setUser(null);
      setIsAdmin(false);
    }
  };

  useEffect(() => {
    const authListener = (data) => {
      switch (data.payload.event) {
        case 'signedIn':
          fetchUser();
          break;
        case 'signedOut':
          setUser(null);
          setIsAdmin(false);
          break;
      }
    };
  
    // Start listening for messages
    const hubListenerCancelToken = Hub.listen('auth', authListener);
  
    // Fetch initial user state
    fetchUser();
  
    // Return a function to stop listening for messages when the component unmounts
    return () => {
      hubListenerCancelToken(); // Stop listening for messages
    };
  }, []);

  // Define a function to check if the current location matches the "Blog" page or its subpages
  const isBlogActive = () => {
    const currentPath = window.location.pathname;
    return currentPath.startsWith('/blog'); // Check if the current path starts with '/blog'
  };
  



  return (
    <div className="root-layout">
      <header>
        <div className="header-content">
          <img src={logo} alt="STATTRAKR logo" />
          
          <nav>
            <div className="nav-item">
              <NavLink
                exact
                to="/"
                className={`nav-link${location.pathname === '/' ? ' active-nav-link' : ''}`}
                aria-label="Home"
              >
                Home
                {isDev && (
                  <div className="home-submenu">
                    <span
                      className="nav-link sign-out"
                      onClick={signOut}
                      tabIndex="0"
                      role="button"
                    >
                      Sign Out
                    </span>
                  </div>
                )}
              </NavLink>
            </div>

            {/**************** Commenting out until future release ****************/}
            {/* <NavLink to="rankings" aria-label="Rankings">
              Rankings
            </NavLink> */}


            <NavLink
              to="/blog"
              className={`nav-link ${isBlogActive() ? 'active-nav-link' : ''}`}
              aria-label="Blog"
            >
              Blog
            </NavLink>
            
            <div
              className={`about-dropdown nav-link${(location.pathname.startsWith('/about') || location.pathname.startsWith('/contact')) ? ' active-submenu' : ''}`}
              onMouseEnter={() => setShowAbout(true)}
              onMouseLeave={() => setShowAbout(false)}
            >
              About
              {showAbout && (
                <div className="submenu">
                  <NavLink to="about" aria-label="About">
                    About
                  </NavLink>
                  <NavLink to="contact" aria-label="Contact">
                    Contact Us
                  </NavLink>
                </div>
              )}
            </div>
            
            
            {isAdmin && (
              <div
                className={`admin-dropdown nav-link${location.pathname.startsWith('/admin') ? ' active-submenu' : ''}`}
                onMouseEnter={() => setShowAdminMenu(true)}
                onMouseLeave={() => setShowAdminMenu(false)}
              >
                Admin
                {showAdminMenu && (
                  <div className="submenu">
                    <NavLink to="/admin/sports" aria-label="Sports">
                      Sports
                    </NavLink>
                    <NavLink to="/admin/leagues" aria-label="Leagues">
                      Leagues
                    </NavLink>
                    <NavLink to="/admin/seasons" aria-label="Seasons">
                      Seasons
                    </NavLink>
                    <NavLink to="/admin/teams" aria-label="Teams">
                      Teams
                    </NavLink>
                  </div>
                )}
              </div>
            )} 
            
            {user ? (
              <div
                className="user-dropdown"
                onMouseEnter={() => setShowUserMenu(true)}
                onMouseLeave={() => setShowUserMenu(false)}
              >
                <span
                  className="nav-link user-name"
                  tabIndex="0"
                  role="button"
                >
                  Hi, {user.attributes.given_name}
                  {showUserMenu && (
                    <div className="user-submenu">
                      <span
                        className="nav-link sign-out"
                        onClick={signOut}
                        tabIndex="0"
                        role="button"
                      >
                        Sign Out
                      </span>
                    </div>
                  )}
                </span>
              </div>
            ) : (
              <span
                className="rounded-button"
                onClick={() => {
                  openModal();
                }}
                tabIndex="0"
                role="button"
                aria-label="Login"
              >
                Login | Sign-up
              </span>
            )}
          </nav>
        </div>
      </header>

      <main>
        <Outlet />
      </main>

      <footer>
        <FooterContent />
      </footer>

      <Modal isOpen={isModalOpen} onClose={closeModal}>
        {showForgotPassword ? (
          <ForgotPassword onClose={closeModal} onShowLogin={showLoginScreen} onShowSignup={showSignup} />
        ) : showLogin ? (
          <Login onClose={closeModal} onShowSignup={showSignup} onShowForgotPassword={showForgotPasswordScreen} />
        ) : (
          <Signup onClose={closeModal} onShowLogin={showLoginScreen} />
        )}
      </Modal>
    </div>
  );
};

export default RootLayout;
