import React from 'react';

const About = () => {
  return (
    <div className="content">
      <div className="about">
        <h1>About Us</h1>
        <section>
          <h2>Who We Are</h2>
          <p>Welcome to STATTRAKR, where we empower youth athletes and their supporters with cutting-edge tools to measure, analyze, and enhance athletic performance across a variety of sports. Beginning with baseball, basketball, football, golf, ice hockey, lacrosse, soccer, softball, tennis, and volleyball, we provide a robust platform for parents, coaches, managers, and trainers to manually record and scrutinize key performance indicators.</p>
          <p>Our web and mobile applications offer personalized reports, charts, graphs, and a global ranking system, positioning athletes based on their sport, age, and performance categories. At STATTRAKR, we value quantitative measurement and trend analysis, placing facts and figures above gut instincts. We are committed to delivering innovative, intuitive, and quality solutions, always with an open ear to our customers' invaluable feedback and insights.</p>
        </section>
        <section>
          <h2>How We Started</h2>
          <p>The seed for STATTRAKR was planted back in 2015, inspired by a father's quest for empirical proof of his son's athletic prowess. Moving beyond the limitations of spreadsheets and manual records, the journey to create a digital solution began, fueled by bootstrapped ambition and relentless self-education in software development.</p>
          <p>Piece by piece, STATTRAKR came to life, evolving from simple notes and spreadsheets into a comprehensive web application set to launch in late 2023 or early 2024. Our founder's personal journey underscores our commitment to innovation, quality, and the democratization of athletic performance analysis.</p>
        </section>
        <section>
          <h2>Where We're Going</h2>
          <p>At STATTRAKR, the future is as bright as the potential of every young athlete we serve. We are on a mission to provide the finest analytical tools, helping youth athletes understand their performance, identify areas for improvement, and achieve their fullest potential.</p>
          <p>Our roadmap is rich with enhancements including live performance data capture, real-time game analysis, coaching feedback, game casts for remote audiences, photo and video galleries, athletes of the week features, and much more. We are not just a service provider; we are a community, a partner in your athlete’s journey, and a champion of their success.</p>
        </section>
        <hr/>
        <p>Join us on this exciting journey at STATTRAKR. Your youth athlete's success is our true mission, and together, we will unlock their full potential.</p>
      </div>
    </div>
  );
};

export default About;
