import { Link, Outlet } from "react-router-dom";

const Admin = () => {
  return (  
    <div className="content">
      <div className="secondaryNav">
        <nav style={{paddingBottom: '2px'}}>
          <Link to="/admin/sports">Sports</Link>
          <Link to="/admin/leagues">Leagues</Link>
          <Link to="/admin/seasons">Seasons</Link>
          <Link to="/admin/teams">Teams</Link>
          {/* <Link to="/admin/users">Users</Link> */}
        </nav>
      </div>

      <Outlet />
    </div>
  );
}
 
export default Admin;