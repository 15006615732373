import React, { useState } from "react";
import { signUp } from 'aws-amplify/auth';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import ReCAPTCHA from "react-google-recaptcha";

const Signup = ({ onClose, onShowLogin }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [firstNameError, setFirstNameError] = useState("");
  const [lastNameError, setLastNameError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [mobileNumberError, setMobileNumberError] = useState("");
  const [recaptchaValue, setRecaptchaValue] = useState(null);
  const [isPasswordFocused, setIsPasswordFocused] = useState(false);
  const [passwordValidations, setPasswordValidations] = useState({
    minLength: false,
    uppercase: false,
    lowercase: false,
    number: false,
    specialChar: false
  });

  const onRecaptchaChange = (value) => {
    setRecaptchaValue(value);
  };

  const handleEmailChange = (event) => setEmail(event.target.value);

  const handlePasswordChange = (event) => {
    const newPassword = event.target.value;
    setPassword(newPassword);
  
    setPasswordValidations({
      minLength: newPassword.length >= 8,
      uppercase: /[A-Z]/.test(newPassword),
      lowercase: /[a-z]/.test(newPassword),
      number: /\d/.test(newPassword),
      specialChar: /[!@#$%^&*(),.?":{}|<>]/.test(newPassword)
    });
  };

  const handleFirstNameChange = (event) => setFirstName(event.target.value);
  const handleLastNameChange = (event) => setLastName(event.target.value);
  const handleMobileNumberChange = (phone) => setMobileNumber(phone);

  const handleSignupSubmit = async (event) => {
    event.preventDefault();
    let formattedNumber = mobileNumber ? (mobileNumber.startsWith('+') ? mobileNumber : `+${mobileNumber}`) : '';

    try {
      await signUp({
        username: email,
        password,
        attributes: {
          email,
          given_name: firstName,
          family_name: lastName,
          phone_number: formattedNumber,
        },
      });
      onClose();
    } catch (error) {
      console.error('Error signing up:', error);
      if (error.message.includes("First name")) {
        setFirstNameError(error.message);
      } else if (error.message.includes("Last name")) {
        setLastNameError(error.message);
      } else if (error.message.includes("Mobile number")) {
        setMobileNumberError(error.message);
      } else if (error.message.includes("Email")) {
        setEmailError(error.message);
      } else if (error.message.includes("Password")) {
        setPasswordError(error.message);
      }
    }
  };

  const handlePasswordFocus = () => {
    setIsPasswordFocused(true);
  };
  
  const handlePasswordBlur = () => {
    setIsPasswordFocused(false);
  };

  return (
    <form className="form-container" onSubmit={handleSignupSubmit}>
      <h2 className="form-heading">Join us!</h2>
      <>
        <div className="input-group">
          <label>First name:</label>
          <input type="text" value={firstName} onChange={handleFirstNameChange} required />
          {firstNameError && <div className="error-message">{firstNameError}</div>}
        </div>
        <div className="input-group">
          <label>Last name:</label>
          <input type="text" value={lastName} onChange={handleLastNameChange} required />
          {lastNameError && <div className="error-message">{lastNameError}</div>}
        </div>
        <div className="input-group">
          <label>Mobile number (optional):</label>
          <PhoneInput
            country={'us'}
            value={mobileNumber}
            onChange={handleMobileNumberChange}
            inputStyle={{width: '95%', display: 'block', height: '40px'}}
            dropdownStyle={{textAlign: 'left'}}
          />
          {mobileNumberError && <div className="error-message">{mobileNumberError}</div>}
        </div>
        <div className="input-group">
          <label>Your email:</label>
          <input type="email" value={email} onChange={handleEmailChange} required />
          {emailError && <div className="error-message">{emailError}</div>}
        </div>
        <div className="input-group">
          <label>Your password:</label>
          <input
            type="password"
            value={password}
            onChange={handlePasswordChange}
            onFocus={handlePasswordFocus}
            onBlur={handlePasswordBlur}
            required
          />
          {isPasswordFocused && (
            <ul className="password-requirements">
              <li className={passwordValidations.minLength ? "valid" : ""}>At least 8 characters</li>
              <li className={passwordValidations.uppercase ? "valid" : ""}>At least one uppercase letter</li>
              <li className={passwordValidations.lowercase ? "valid" : ""}>At least one lowercase letter</li>
              <li className={passwordValidations.number ? "valid" : ""}>At least one number</li>
              <li className={passwordValidations.specialChar ? "valid" : ""}>At least one special character</li>
            </ul>
          )}
          {passwordError && <div className="error-message">{passwordError}</div>}
        </div>
        <ReCAPTCHA
          sitekey="6LeBIyspAAAAACmLy_c2nWtTTyLJ5ofcE74BDGK7"
          onChange={onRecaptchaChange}
        />
        <button className={recaptchaValue ? "submit-button" : "submit-button-disabled"} type="submit" disabled={!recaptchaValue}>
          Sign up
        </button>
      </>
      <div className="links">
        <a href="#" onClick={(event) => {
          event.preventDefault();
          onShowLogin();
        }}>
          Already have an account?
        </a>
      </div>
    </form>
  );
};

export default Signup;
