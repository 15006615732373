import { Link } from "react-router-dom";

export default function NotAuthorized() {
  return (
    <div className="content">
      <div style={{maxWidth: '1200px', minWidth: '600px', margin: '40px auto'}}>
        <h2 style={{marginBottom: '20px'}}>Oops! You're not authorized.</h2>
        <p style={{marginBottom: '10px'}}>Looks like you've stumbled upon an area that's off-limits.</p>
        <p style={{marginBottom: '10px'}}>Don't worry, it's nothing personal! You just might not have the right clearance for this part of the mission.</p>
        <p>If you think you should have access, reach out to your administrator. In the meantime, how about we take you back to safer grounds?</p>
        <p>Go to the <Link to="/">Homepage</Link>.</p> 
      </div>
    </div>
  )
}
