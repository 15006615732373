import { Link, useLocation } from "react-router-dom"

export default function BlogDetails() {
  const location = useLocation();
  const blog = location.state.blog;

  return (
    <div className="content">
      <div style={{width: '800px', margin: '20px auto',}}>
        <Link to="/blog">Go back</Link>
      </div>
      <div className="blogDetails">
        <h1>{ blog.Title }</h1>
        <div className="blogMeta">
          <p className="metaText">{ blog.PublishedDate }</p>
          <p style={{margin: '0 10px', color: '#737C8C'}}>•</p>
          {blog.Categories.map((category) =>
            <p className="metaText">{ category }</p>
          )}
        </div>
        <div dangerouslySetInnerHTML={{__html:blog["Content"]}}></div>
      </div>
    </div>
  )
}
