/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-2",
    "aws_cloud_logic_custom": [
        {
            "name": "AdminQueries",
            "endpoint": "https://5e32ca8w5b.execute-api.us-east-2.amazonaws.com/dev",
            "region": "us-east-2"
        }
    ],
    "aws_appsync_graphqlEndpoint": "https://2tpfv6uaffdb5eqmuemi64rq3u.appsync-api.us-east-2.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-2",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-gq3uqsyemnaj5fozxjse4umo2u",
    "aws_cognito_identity_pool_id": "us-east-2:9fdd41f1-489f-4972-9f5c-f8bfa6ecc2a5",
    "aws_cognito_region": "us-east-2",
    "aws_user_pools_id": "us-east-2_b8qjZS96G",
    "aws_user_pools_web_client_id": "5hcfqkb200s7hrogmblehc1e3m",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ]
};


export default awsmobile;
