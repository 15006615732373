import { Link } from "react-router-dom";

export default function NotFound() {
  return (
    <div className="content">
      <div style={{maxWidth: '1200px', minWidth: '600px', margin: '40px auto'}}>
        <h2 style={{marginBottom: '20px'}}>Oops!!! Page not found!</h2>
        <p style={{marginBottom: '10px'}}>It seems you have called for a page that doesn't exist.</p>

        <p>Go to the <Link to="/">Homepage</Link>.</p> 
      </div>
    </div>
  )
}
