import * as React from "react";
import { useState } from "react";
import {
  Table,
  TableCell,
  TableBody,
  TableHead,
  TableRow
} from '@aws-amplify/ui-react';
import { useLoaderData } from "react-router-dom";

const Leagues = () => {
  const initialLeagues = useLoaderData();
  const [leagues, setLeagues] = useState(initialLeagues);
  const [sortConfig, setSortConfig] = useState({ key: null, direction: 'asc' });

  // Function to handle column header click
  const handleSort = (key) => {
    let direction = 'asc'; // Default to ascending order
    if (sortConfig.key === key && sortConfig.direction === 'asc') {
      // If the same column is clicked again, toggle the sorting direction
      direction = 'desc';
    }
    setSortConfig({ key, direction });
    
    // Sort the leagues based on the selected column and order
    const sorted = [...leagues].sort((a, b) => {
      if (a[key] < b[key]) return direction === 'asc' ? -1 : 1;
      if (a[key] > b[key]) return direction === 'asc' ? 1 : -1;
      return 0;
    });
    setLeagues(sorted);
  };

  // Function to render the sort icon
  const renderSortIcon = (columnKey) => {
    if (sortConfig.key === columnKey) {
      return sortConfig.direction === 'asc' ? '↑' : '↓';
    }
    return null;
  };

  return (  
    <div className="content">
      <div className="admin">
        <h1>Leagues</h1>
        <div className="league-content">
          <Table>
            <TableHead>
              <TableRow>
                <TableCell as="th" onClick={() => handleSort('Sport')}>
                  Sport {renderSortIcon('Sport')}
                </TableCell>
                <TableCell as="th" onClick={() => handleSort('Name')}>
                  League Name {renderSortIcon('Name')}
                </TableCell>
                <TableCell as="th" onClick={() => handleSort('Abbreviation')}>
                  Abbr. {renderSortIcon('Abbreviation')}
                </TableCell>
                <TableCell as="th" onClick={() => handleSort('Location')}>
                  Location {renderSortIcon('Location')}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {leagues.map((item, index) => (
                <TableRow key={index}>
                  <TableCell>{item.Sport}</TableCell>
                  <TableCell>{item.Name}</TableCell>
                  <TableCell>{item.Abbreviation}</TableCell>
                  <TableCell>{item.Location}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>
      </div>
    </div>
  );
}
 
export default Leagues;


// loader function
export const leaguesLoader = async () => {
  try {
    const res = await fetch("https://qrqw1q3mhg.execute-api.us-east-2.amazonaws.com/test/leagues");
    // console.log('Respose: ', res);
    if (!res.ok) {
      throw Error("Could not fetch the leagues.");
    }
    const data = await res.json();
    const leagues = JSON.parse(data.body);

    // Sorting the leagues array alphabetically by LeagueName
    leagues.sort((a, b) => {
      if (a.LeagueName < b.LeagueName) {
        return -1;
      }
      if (a.LeagueName > b.LeagueName) {
        return 1;
      }
      return 0;
    });

    // console.log('Sorted Leagues: ', leagues);
    return leagues;
  } catch (error) {
    throw error;
  }
};