import React from 'react';
import { Link } from 'react-router-dom';

export default function ThankYou() {
  return (
    <div className="content">
      <div className="thankYou">
        <h1>Thank You</h1>
        <p>Thank you the message. We truly value your input. If your message requires a response, we will do our best to get back to you in a reasonable timeframe.</p>
        <p>If you don't hear from us and you think it has been way too long to not have heard anything, please don't take it personal. We do get busy from time to time as we are spending every free moment trying to make STATTRAKR the best analytics platform for youth athletics there is.</p>

        <p>Go to the <Link to="/">Homepage</Link>.</p> 
      </div>
    </div>
  );
}
