import * as React from 'react';
import { 
  createBrowserRouter,
  Route, 
  createRoutesFromElements,
  RouterProvider
} from 'react-router-dom';

// pages
import Home from './Pages/Home';
import Blog, { blogLoader } from './Pages/Blog/Blog';
import BlogDetails from './Pages/Blog/BlogDetails';
import BlogError from './Pages/Blog/BlogError';
import About from './Pages/Company/About';
import ContactUs from './Pages/Company/ContactUs';
import ThankYou from './Pages/Company/ThankYou';
import NotFound from './Pages/NotFound';
import NotAuthorized from './Pages/NotAuthorized';

// ranking pages
import HockeyRankings, { statLoader } from './Pages/Rankings/HockeyRankings';


// admin pages
import Seasons, { seasonAction, seasonsLoader } from './Pages/Admin/Seasons';
import Leagues, { leaguesLoader } from './Pages/Admin/Leagues';
import Teams, { teamAction, teamsLoader } from './Pages/Admin/Teams';
import Sports, { sportAction, sportsLoader } from './Pages/Admin/Sports';
// import Users from './Pages/Admin/Users';

// layouts
import RootLayout from './layouts/RootLayout';
import BlogLayout from './layouts/BlogLayout';
import AdminLayout from './layouts/AdminLayout';
import RankingsLayout from './layouts/RankingsLayout';

// context and private route
// import { UserProvider } from './contexts/UserContext';
import PrivateRoute from './components/PrivateRoute';

const cors = require('cors');

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<RootLayout />}>
      <Route index element={<Home />} />

      {/**************** Commenting out until future release ****************/}
      {/* <Route path="rankings" element={<RankingsLayout />}>
        <Route path='hockey' element={<HockeyRankings />} loader={statLoader} />
      </Route> */}

      <Route path="blog" element={<BlogLayout />} errorElement={<BlogError />}>
        <Route index element={<Blog />} loader={blogLoader} />
        <Route path=':id' element={<BlogDetails />} />
      </Route>
      <Route path="about" element={<About />} />
      <Route path="contact" element={<ContactUs />}/>
      <Route path="thank-you" element={<ThankYou />}/>
      

      {/* Protected admin routes */}
      <Route path="/admin" element={<PrivateRoute requiredRoles={['admin']} />}>
        <Route element={<AdminLayout />}>
          <Route index element={<Sports />} loader={sportsLoader} action={sportAction} />
          <Route path="sports" element={<Sports />} loader={sportsLoader} action={sportAction} />
          <Route path="leagues" element={<Leagues />} loader={leaguesLoader} />
          <Route path="seasons" element={<Seasons />} loader={seasonsLoader} action={seasonAction} />
          <Route path="teams" element={<Teams />} loader={teamsLoader} action={teamAction} />
        </Route>
      </Route>

      
      <Route path="not-authorized" element={<NotAuthorized />} />
      <Route path="*" element={<NotFound />} />
    </Route>
  )
);

function App() {
  return (
      <RouterProvider router={router} />
  );
}

export default App;