import { Link, useRouteError } from "react-router-dom";

export default function BlogError() {
  const error = useRouteError()

  return (
    <div className="content">
      <div className="blogError">
        <h1>Error</h1>
        <p>{error.message}</p>
        <Link to="/">Back to homepage</Link>
      </div>
    </div>
  )
}
