import React, { useState, useEffect } from "react";
import { useLoaderData, Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';

export const blogLoader = async () => {
  try {
    const res = await fetch("https://v03t4wfr3e.execute-api.us-east-2.amazonaws.com/test/blogposts");
    if (!res.ok) {
      throw Error("Could not fetch the blogs.");
    }
    const response = await res.json();
    const blogs = JSON.parse(response.body);
    const sortedBlogs = blogs.sort((a, b) => a.BlogID - b.BlogID);
    return sortedBlogs;
  } catch (error) {
    throw error;
  }
};

const Blog = () => {
  const initialBlogs = useLoaderData(blogLoader);
  const [blogs, setBlogs] = useState(initialBlogs);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("");
  const [sortBy, setSortBy] = useState("BlogID");

  useEffect(() => {
    async function loadBlogs() {
      try {
        const blogs = await blogLoader();
        setBlogs(blogs);
      } catch (error) {
        console.error("Error loading blogs:", error);
      }
    }
    loadBlogs();
  }, []);

  const handleSortChange = (value) => {
    setSortBy(value);
  };

  const handleCategoryChange = (value) => {
    setSelectedCategory(value);
  };

  const filteredBlogs = blogs.filter((blog) => {
    const searchLower = searchTerm.toLowerCase();
    const titleMatch = blog.Title.toLowerCase().includes(searchLower);
    const categoryMatch = blog.Categories.some(cat => cat.toLowerCase().includes(searchLower));
    const authorMatch = blog.Author.toLowerCase().includes(searchLower);
    return titleMatch || categoryMatch || authorMatch;
  });

  const sortedBlogs = [...filteredBlogs].sort((a, b) => {
    if (sortBy === "PublishedDate") {
      return new Date(b.PublishedDate) - new Date(a.PublishedDate);
    } else if (sortBy === "Title") {
      return a.Title.localeCompare(b.Title);
    } else if (sortBy === "Author") {
      return a.Author.localeCompare(b.Author);
    } else {
      return a.BlogID - b.BlogID;
    }
  });

  return (
    <div className="content">
      <div style={{ maxWidth: "800px", margin: "0 auto" }}>
        <div className="blog">
          <h1>Blog</h1>
          <div className="search-filter">
            <div className="search-group">
              <FontAwesomeIcon icon={faSearch} />
              <input
                type="text"
                className="search-input"
                placeholder="Search by title, category, or author"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
              <button className="search-button">Search</button>
            </div>
          </div>

          {sortedBlogs.map((blog) => (
            <div className="blogPreview" key={blog.BlogID}>
              {blog.BlogImageURL && (
                <div className="blogImage">
                  <img src={blog.BlogImageURL} alt="Blog" width="267" />
                </div>
              )}
              <div className="blogContent">
                <div className="blogMeta">
                  <p className="metaText">{blog.PublishedDate}</p>
                  <p style={{ margin: "0 10px", color: "#737C8C" }}>•</p>
                  {blog.Categories.map((category) => (
                    <p className="boxed" key={category}>
                      {category}
                    </p>
                  ))}
                </div>
                <Link to={`/blog/${blog.BlogID}`} state={{ blog }} className="blogTitleLink">
                  <h2 className="blogTitle">{blog.Title}</h2>
                </Link>
                <p className="blogSummary">{blog.Summary}</p>
                <Link to={`/blog/${blog.BlogID}`} state={{ blog }} className="blogReadMore">
                  Read more
                </Link>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Blog;