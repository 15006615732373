import React, { useState } from "react";
import { resetPassword, confirmResetPassword } from 'aws-amplify/auth'; // Updated imports

function isValidEmail(email) {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
}

const ForgotPassword = ({ onClose, onShowLogin, onShowSignup }) => {
  const [email, setEmail] = useState("");
  const [isEmailValid, setIsEmailValid] = useState(true);
  const [error, setError] = useState("");
  const [isCodeSent, setIsCodeSent] = useState(false);
  const [code, setCode] = useState("");
  const [newPassword, setNewPassword] = useState("");

  const handleEmailChange = (event) => {
    const newEmail = event.target.value;
    setEmail(newEmail);
    setIsEmailValid(isValidEmail(newEmail));
  };

  const handleSendCode = async (event) => {
    event.preventDefault();
    try {
      await resetPassword({ username: email }); // Use resetPassword for sending the code
      setIsCodeSent(true);
    } catch (error) {
      console.log('Error sending reset code', error);
      setError(error.message || "An error occurred while sending the reset code");
    }
  };

  const handleResetPassword = async (event) => {
    event.preventDefault();
    try {
      await confirmResetPassword({
        username: email,
        code: code,
        newPassword: newPassword
      }); // Use confirmResetPassword for resetting the password
      onClose();
    } catch (error) {
      console.log('Error resetting password', error);
      setError(error.message || "An error occurred while resetting the password");
    }
  };

  return (
    <div className="form-container">
      {!isCodeSent ? (
        <form onSubmit={handleSendCode}>
          <h2 className="form-heading">Reset Password</h2>
          <div className="input-group">          
            <label htmlFor="email">Email Address:</label>
            <input
              type="email"
              id="email"
              value={email}
              onChange={handleEmailChange}
              required
            />
            {!isEmailValid && <p className="error-text">Please enter a valid email address.</p>}
            {error && <p className="error-text">{error}</p>}
          </div>
          <button type="submit" className="submit-button" disabled={!isEmailValid}>
            Send Reset Code
          </button>
          <div className="links">
            <a href="#" onClick={(event) => {
                event.preventDefault();
                onShowLogin();
              }}>
                Back to login
            </a>
            <span className="link-separator">|</span>
            <a href="#" onClick={(event) => {
              event.preventDefault();
              onShowSignup();  
            }}>
              Register or Verify account
            </a>
          </div>
        </form>
      ) : (
        <form onSubmit={handleResetPassword}>
          <h2 className="form-heading">Enter Reset Code</h2>
          <div className="input-group">
            <label htmlFor="code">Reset Code:</label>
            <input
              type="text"
              id="code"
              value={code}
              onChange={(e) => setCode(e.target.value)}
              required
            />
          </div>
          <div className="input-group">
            <label htmlFor="newPassword">New Password:</label>
            <input
              type="password"
              id="newPassword"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              required
            />
            {error && <p className="error-text">{error}</p>}
          </div>
          <button type="submit" className="submit-button">
            Reset Password
          </button>
        </form>
      )}
    </div>
  
  );
};

export default ForgotPassword;
