import React, { useContext } from 'react';
import { Navigate, useLocation, Outlet } from 'react-router-dom';
import { useUser } from '../contexts/UserContext';

const PrivateRoute = ({ requiredRoles }) => {
  const location = useLocation();
  const { userGroups } = useUser(); // Get user's groups from context
  const isAuthenticated = userGroups.length > 0; // Assume user is authenticated if they have groups
  const hasRequiredRole = requiredRoles ? requiredRoles.some(role => userGroups.includes(role)) : true;

  if (!isAuthenticated) {
    // Redirect them to the login page
    return <Navigate to="/" state={{ from: location }} replace />;
  } else if (requiredRoles && !hasRequiredRole) {
    // User is authenticated but does not have the required role
    // Redirect to a 'Not Authorized' page or similar
    return <Navigate to="/not-authorized" state={{ from: location }} replace />;
  }

  return <Outlet />;
};

export default PrivateRoute;
