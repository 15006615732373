const FooterContent = () => {
  const current = new Date();
  let year = current.getFullYear();

  return (  
    <footer>
      <div>
        <p>&copy;{year} STATTRAKR</p>
      </div>
        
    </footer>
  );
}
 
export default FooterContent;