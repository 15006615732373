
import * as React from "react";
import { useState } from "react";
import { Form, redirect, useLoaderData } from "react-router-dom";
import { Accordion } from "@aws-amplify/ui-react";
import { v4 as uuidv4 } from 'uuid';
import { FaMinus, FaPlus } from "react-icons/fa";


const Sports = () => {
  const sports = useLoaderData()
  const [showPositions, setShowPositions] = useState(false); // Initialize as hidden
  const [showPerformanceIndicators, setShowPerformanceIndicators] = useState(false); // Initialize as hidden
  // const [dateStart, setDateStart] = useState(new Date())
  // const [dateEnd, setDateEnd] = useState(new Date())
  const [addSport, setAddSport] = useState(false)
  const sportClick = () => {
    setAddSport(!addSport)
  }

  return (  
    <div className="content">
      <div className="admin">
        <h1>Sports</h1>
        <Accordion.Container className="sports-accordion">
        {sports && sports.map((sport) => (
          <Accordion.Item value={sport.SportId} className="accordion-item">
            <Accordion.Trigger className="accordion-trigger">
              {sport.SportName}
              <Accordion.Icon />
            </Accordion.Trigger>
            <Accordion.Content>
              <div className="sport-description">
                {sport.Description}
              </div>
              <div className="sport-positions">
                <h3>
                  Positions{' '}
                  <span
                    className={`positions-toggle ${showPositions ? 'show' : 'hide'}`}
                    onClick={() => setShowPositions(!showPositions)}
                  >
                    {showPositions ? <FaMinus className="fa-icon" /> : <FaPlus className="fa-icon" />}
                  </span>
                </h3>
                {showPositions && sport.PositionNames && (
                  <ul className={`position-list ${sport.PositionNames.length > 8 ? 'three-columns' : sport.PositionNames.length > 4 ? 'two-columns' : ''}`}>
                    {sport.PositionNames.map((position, index) => (
                      <li key={index}>
                        {position}
                      </li>
                    ))}
                  </ul>
                )}
              </div>
            </Accordion.Content>
          </Accordion.Item>
        ))}
        </Accordion.Container>
      </div>
    </div>
  );
}
 
export default Sports;

// loader function
export const sportsLoader = async () => {
  try {
    const res = await fetch("https://jgmrsx1ehb.execute-api.us-east-2.amazonaws.com/test/sports");
    if (!res.ok) {
      throw Error("Could not fetch the sports.");
    }
    const data = await res.json();
    const sports = JSON.parse(data.body);

    // Sorting the sports array alphabetically by SportName
    sports.sort((a, b) => {
      if (a.SportName < b.SportName) {
        return -1;
      }
      if (a.SportName > b.SportName) {
        return 1;
      }
      return 0;
    });

    // console.log('Sorted Sports: ', sports);
    return sports;
  } catch (error) {
    throw error;
  }
};


// post function
export const sportAction = async ({ request }) => {

  const data = await request.formData()

  const submission = {
    sportId: uuidv4(),
    sportName: data.get('sportName')
  }

  // console.log(submission)

  // send post request
  await fetch('http://localhost:8000/sports', {
    method: 'POST',
    body: JSON.stringify(submission),
    headers: { 'Content-Type': 'application/json' }
  })

  // redirect the user
  return redirect('/')

}