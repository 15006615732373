import { useState, useEffect } from "react";
import { Form, redirect, useLoaderData } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { v4 as uuidv4 } from 'uuid';

const Seasons = () => {
  const seasons = useLoaderData()
  const [dateStart, setDateStart] = useState(new Date())
  const [dateEnd, setDateEnd] = useState(new Date())
  const [addSeason, setAddSeason] = useState(false)
  const seasonClick = () => {
    setAddSeason(!addSeason)
  }

  // useEffect(() => {
  //   console.log('Seasons is being rendered');
  // }, []);

  return (  
    <div className="content">
      <div className="admin">
        <h1>Seasons</h1>
        <div style={{display: "flex", flexDirection: "row", flexWrap: "wrap", justifyContent: "left"}}>
          {seasons.map((season) => (
            <div className="seasonCard" key={season.id}>
              <h4>{ season.seasonName }</h4>
              <p style={{marginBottom: "2px"}}>{ season.sportName }</p>
              <div>
                <p>{ season.dateStart } to { season.dateEnd }</p>
              </div>
            </div>
          ))}
        </div>
        {addSeason && 
          <Form method="post" action="/admin/seasons/">
            <label>
              <span>Season name:</span>
              <input type="text" name="seasonName" required />
            </label>
            <div style={{display: "flex", flexDirection: "column"}}>
              <label>
                <span>Start date:</span>
              </label>
              <DatePicker
                name="dateStart"
                selected={dateStart}
                onChange={(date) => {setDateStart(date)}}
                dateFormat="MM-yyyy"
                showMonthYearPicker
                required
              />
              <label>
                <span>End date:</span>
              </label>
              <DatePicker
                name="dateEnd"
                selected={dateEnd}
                onChange={(date) => setDateEnd(date)}
                dateFormat="MM-yyyy"
                showMonthYearPicker
                required
              />
            </div>
            <button style={{marginRight: '10px'}}>Submit</button>
            <button onClick={seasonClick}>Cancel</button>

          </Form>
        }
        {!addSeason && <button onClick={seasonClick} style={{marginTop: "20px"}}>Add Season</button>}
      </div>
    </div>
  );
}
 
export default Seasons;

// loader function
export const seasonsLoader = async () => {
  const res = await fetch('http://localhost:8000/seasons')

  if (!res.ok) {
    throw Error('Could not fetch the seasons.')
  }
  
  return res.json()
}

export const seasonAction = async ({ request }) => {

  const data = await request.formData()

  const submission = {
    seasionId: uuidv4(),
    seasonName: data.get('seasonName'),
    dateStart: data.get('dateStart'),
    dateEnd: data.get('dateEnd')
  }

  // console.log(submission)

  // send post request
  await fetch('http://localhost:8000/seasons', {
    method: 'POST',
    body: JSON.stringify(submission),
    headers: { 'Content-Type': 'application/json' }
  })

  // redirect the user
  return redirect('/')

}