import { useEffect, useRef, useState } from "react";

const ImageSlider = ({slides}) => {
  const timerRef = useRef(null);
  const [currentIndex, setCurrentIndex] = useState(0);

  const goToNext = () => {
    const isLastSlide = currentIndex === slides.length - 1;
    const newIndex = isLastSlide ? 0 : currentIndex + 1;
    setCurrentIndex(newIndex);
  };

  const sliderStyles = {
    height: '100%',
    position: 'relative',
  };

  const slideStyles = {
    width: '100%',
    height: '100%',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundImage: `url(${slides[currentIndex].url})`,
  };

  useEffect(() => {
    // console.log(slides[currentIndex].title);
    timerRef.current = setTimeout(() => {
      goToNext();
    }, 4000)
  });

  return (  
    <div style={sliderStyles}>
      <div style={slideStyles}>
        <div className="heroTitleArea">
          <div className="heroTitle">
            <p style={{color: '#fff'}}>{slides[currentIndex].title}</p>
          </div>
          <div className="heroSubtitle">
            <p style={{color: '#fff'}}>{slides[currentIndex].subtitle}</p>
          </div>
        </div>
      </div>
    </div>
  );
}
 
export default ImageSlider;