import React, { useState, useContext } from "react";
import { signIn, fetchAuthSession } from 'aws-amplify/auth';
import UserContext from '../contexts/UserContext';

function isValidEmail(email) {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
}

function isPasswordValid(password) {
  const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
  return passwordRegex.test(password);
}

const Login = ({ onClose, onShowSignup, onShowForgotPassword }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isEmailValid, setIsEmailValid] = useState(true);
  const [error, setError] = useState("");
  const { setUserGroups } = useContext(UserContext);

  const handleEmailChange = (event) => {
    const newEmail = event.target.value;
    setEmail(newEmail);
    setIsEmailValid(isValidEmail(newEmail));
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const fetchUserGroups = async () => {
    try {
      const authSession = await fetchAuthSession();
  
      // Check if the idToken and payload are available
      if (authSession && authSession.tokens && authSession.tokens.idToken && authSession.tokens.idToken.payload) {
        // Extract the user groups
        const groups = authSession.tokens.idToken.payload['cognito:groups'] || [];
  
        setUserGroups(groups);
      } else {
        console.error('Invalid session structure:', authSession);
      }
    } catch (error) {
      console.error('Error fetching user groups:', error);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (isPasswordValid(password)) {
      try {
        await signIn({ username: email, password: password });
        await fetchUserGroups();
        onClose();
      } catch (error) {
        console.log('Error signing in', error);
        setError(error.message || "An error occurred during sign in");
      }
    } else {
      console.log("Invalid password.");
    }
  };

  return (
    <form className="form-container" onSubmit={handleSubmit}>
      <h2 className="form-heading">Welcome back!</h2>
      <div className="input-group">
        <label>Your email:</label>
        <input type="email" value={email} onChange={handleEmailChange} />
        {!isEmailValid && <span className="error-message">Invalid email format</span>}
        {error && <div className="error-message">{error}</div>}
      </div>
      <div className="input-group">
        <label>Your password:</label>
        <input type="password" value={password} onChange={handlePasswordChange} />
      </div>
      <button className="login-button" type="submit">
        Log in
      </button>
      <div className="links">
        <a href="#" onClick={(event) => {
          event.preventDefault();
          onShowForgotPassword();
        }}>
          Forgot password?
        </a>
        <span className="link-separator">|</span>
        <a href="#" onClick={(event) => {
          event.preventDefault();
          onShowSignup();  
        }}>
          Sign up for an account
        </a>
      </div>
    </form>
  );
};

export default Login;