import React, { createContext, useState, useContext, useEffect } from 'react';

const UserContext = createContext();

export const useUser = () => {
  const context = useContext(UserContext);
  if (context === undefined) {
    throw new Error('useUser must be used within a UserProvider');
  }
  return context;
};

export const UserProvider = ({ children }) => {
  const [userGroups, setUserGroups] = useState([]);

  // useEffect(() => {
  //   console.log('UserProvider Mounted');
  //   return () => {
  //     console.log('UserProvider Unmounted');
  //   };
  // }, []);

  // useEffect(() => {
  //   console.log('User Groups Updated:', userGroups);
  // }, [userGroups]);

  return (
    <UserContext.Provider value={{ userGroups, setUserGroups }}>
      {children}
    </UserContext.Provider>
  );
};

export default UserContext;
