import React, { useEffect, useRef, useState } from 'react';
import Hero from "../components/Hero";
import hockey_player from "../images/hockey_player.jpg"
import CountUp from 'react-countup';
import { MdPeopleAlt, MdOutlineCalendarMonth, MdOutlineSportsHockey, MdOutlineBarChart } from "react-icons/md";

const Home = () => {
  const containerRef = useRef(null);
  const [countersVisible, setCountersVisible] = useState(false);

  useEffect(() => {
    const options = {
      root: null, // viewport
      rootMargin: '0px',
      threshold: 0.5, // trigger when 50% of the element is visible
    };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          // Start the count-up animation when counters become visible
          setCountersVisible(true);
          // Disconnect the observer after triggering the animation
          observer.disconnect();
        }
      });
    }, options);

    if (containerRef.current) {
      observer.observe(containerRef.current);
    }

    // Cleanup the observer when the component is unmounted
    return () => {
      observer.disconnect();
    };
  }, []); // Empty dependency array ensures this effect runs once after the initial render

  return (
    <div className="content">
      <div>
        <Hero />
      </div>

      {/* Our Solution */}
      <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', backgroundColor: '#f7f7f7', padding: '100px 0', alignItems: 'center'}}>
        <div style={{maxWidth: '1000px',}}>
          <h2 style={{marginBottom: '20px', fontSize: '2.5vw', textAlign: 'center', fontWeight: 1000}}>OUR SOLUTION</h2>
          <p style={{textAlign: 'justify', fontSize: '1.25vw'}}>STATTRAKR is a purpose-built platform for capturing and analyzing key athletic performance indicators. Tracking performance provides valuable insights that can help athletes of any level reach their goals by identifying areas of deficiency for targeted improvement. Using STATTRAKR to monitor performance season-over-season, or even game-over-game, is the quickest way to identify lagging indicators through quantifiable measurements and analysis. Take 'gut instinct' out of the equation and start using STTATRAKR today.</p>
        </div>
      </div>

      {/* Benefits of Tracking Performance */}
      <div className="container" style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', padding: '100px 0', alignItems: 'center', backgroundColor: '#fff' }}>
        <div style={{display: 'flex', width: '1000px', justifyContent: 'flex-start'}}>
          <div style={{width: '50%'}}>
            <div style={{marginBottom: '10px'}}>
              <h2 style={{fontSize: '2vw', fontWeight: 900, letterSpacing: '5px'}}>Benefits of Tracking Performance</h2>
              <p>Tracking performance metrics provides athletes with valuable data to analyze, set goals, and optimize their training.</p>
            </div>
            <div style={{display: 'flex', flexDirection: 'column', fontSize: '1.5em', gap: '20px'}}>
              <p>Identify Strengths and Weaknesses</p>
              <p>Monitor Progress</p>
              <p>Data-Driven Training</p>
              <p>Enhancing Mental Focus</p>
              <p>Performance Analysis</p>
            </div>
          </div>
          <div style={{marginLeft: '20px'}}>
            <img src={hockey_player} alt="hockey player" height="400" />
          </div>
        </div>
      </div>

      {/* Quote Banner */}
      <div 
        style={{
          display: 'flex', 
          flexDirection: 'column', 
          justifyContent: 'center', 
          backgroundColor: 'orange', 
          padding: '100px 0', 
          alignItems: 'center'}}>
        <div style={{maxWidth: '1000px',}}>
          <h2 style={{marginBottom: '20px', fontSize: '2.5vw', textAlign: 'center', fontWeight: 1000}}>“If you can’t measure it, you can’t improve it.”</h2>
          <p style={{textAlign: 'right', fontSize: '1.25vw'}}>– Peter Drucker</p>
        </div>
      </div>
      
      {/* Our Mission */}
      <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', backgroundColor: '#f7f7f7', padding: '100px 0', alignItems: 'center'}}>
        <div style={{maxWidth: '1000px',}}>
          <h2 style={{marginBottom: '20px', fontSize: '2.5vw', textAlign: 'center', fontWeight: 1000}}>OUR MISSION</h2>
          <p style={{textAlign: 'justify', fontSize: '1.25vw', paddingBottom: '20px'}}>At STATTRAKR, our mission is to empower youth athletes, parents, and coaches with data-driven tools that fuel athletic development. We believe in unlocking the full potential of every young athlete, enabling them to identify strengths and areas for growth with precision.</p>
          <p style={{textAlign: 'justify', fontSize: '1.25vw', paddingBottom: '20px'}}>We're more than just numbers; we're dedicated partners on the path to excellence. We provide insights that foster holistic growth, support parents in their child's journey, and assist coaches in crafting targeted training regimens.</p>
          <p style={{textAlign: 'justify', fontSize: '1.25vw', paddingBottom: '20px'}}>STATTRAKR is committed to nurturing the dreams of youth athletes, celebrating victories, and turning weaknesses into strengths. We're here to help every young athlete reach their full potential, one data point at a time.</p>
        </div>
      </div>

      {/* Counters */}
      <div className="counter-container" ref={containerRef}>
        <div className="counter-item">
          <MdPeopleAlt className="counter-icon" />
          {countersVisible && <CountUp end={24588} duration={2} className="counter-number" />}
          <p className="counter-label">Athletes</p>
        </div>
        <div className="counter-item">
          <MdOutlineCalendarMonth className="counter-icon" />
          {countersVisible && <CountUp end={36466} duration={2} className="counter-number" />}
          <p className="counter-label">Seasons</p>
        </div>
        <div className="counter-item">
          <MdOutlineSportsHockey className="counter-icon" />
          {countersVisible && <CountUp end={282488} duration={2} className="counter-number" />}
            <p className="counter-label">Games</p>
        </div>
        <div className="counter-item">
          <MdOutlineBarChart className="counter-icon" />
          <div className="bigCounter" style={{display: 'flex', flexDirection: 'row'}}>
            {countersVisible && <CountUp end={36} duration={2} className="counter-number" />}
            <p className="counter-number">.86M</p>
          </div>
          <p className="counter-label">Stats</p>
        </div>
      </div>
      


    </div>
  );
}
 
export default Home;