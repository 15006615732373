import React from "react";
import { MdClose } from "react-icons/md";

const Modal = ({ isOpen, onClose, children }) => {
  if (!isOpen) return null;

  return (
    <div className="modal-overlay" onClick={onClose}>
      <div className="modal-content" onClick={(e) => e.stopPropagation()}>
        <MdClose 
          color="orange"
          onClick={onClose} 
          size={24}
          style={{ position: "absolute", top: "10px", right: "10px", cursor: "pointer" }}
        />
        {children}
      </div>
    </div>
  );
};

export default Modal;
