import { useState, useEffect } from "react";
import { Form, redirect, useLoaderData } from "react-router-dom";
import { v4 as uuidv4 } from 'uuid';
import useFetch from "../../components/useFetch";

const Teams = () => {
  const teams = useLoaderData()
  const { data: sports } = useFetch('http://localhost:8000/sports')
  const { data: seasons } = useFetch('http://localhost:8000/seasons');
  const [addTeam, setAddTeam] = useState(false)
  const clickAddTeam = () => {
    setAddTeam(true)
  }
  const cancelAddTeam = () => {
    setAddTeam(false)
  }
  
  useEffect(() => {
    console.log('Teams is being rendered');
  }, []);

  return (  
    <div className="content">
      <div className="admin">
        <h1>Teams</h1>
        <div style={{display: "flex", flexDirection: "row", flexWrap: "wrap"}}>
          {teams.map((team) => (
            <div className="teamCard" key={team.id}>
              <h2>{ team.teamName }</h2>
              <p>{ team.sportName}</p>
              <p>{ team.seasonName }</p>
              <p>Record: { team.record }</p>
            </div>
          ))}
        </div>
        {addTeam && 
          <Form method="post" action="/admin/teams/">
            <label>
              <span>Team name:</span>
              <input type="text" name="teamName" required />
            </label>
            <label htmlFor="sport">Pick a sport:</label>
            <br />
            <select name="sportId" id="sportId">
              <option value="default" selected>Select a sport</option>
              {sports.map((sport) => (
                <option value={sport.sportId + ':' + sport.sportName}>{sport.sportName}</option>
              ))}
            </select>
            <br />
            <label htmlFor="season">Pick a season:</label>
            <br />
            <select name="seasonId" id="seasonId">
              <option value="default" selected>Select a season</option>
              {seasons.map((season) => (
                <option value={season.seasonId + ':' + season.seasonName}>{season.seasonName}</option>
              ))}
            </select>
            <label>
              <span>Record:</span>
              <input type="text" name="record" required />
            </label>
            <button style={{marginRight: '10px', marginTop: "20px"}}>Submit</button>
            <button onClick={cancelAddTeam}>Cancel</button>

          </Form>
        }
        {!addTeam && <button onClick={clickAddTeam} style={{marginTop: "20px"}}>Add Team</button>}
      </div>
    </div>
  );
}
 
export default Teams;

// loader function
export const teamsLoader = async () => {
  const res = await fetch('http://localhost:8000/teams')

  if (!res.ok) {
    throw Error('Could not fetch the teams.')
  }
  
  return res.json()
}

// post function
export const teamAction = async ({ request }) => {

  const data = await request.formData()
  let seasonId = data.get('seasonId').slice(0,36)
  let seasonName = data.get('seasonId').slice(37)
  let sportId = data.get('sportId').slice(0,36)
  let sportName = data.get('sportId').slice(37)

  const submission = {
    teamId: uuidv4(),
    seasonId: seasonId,
    seasonName: seasonName,
    sportId: sportId,
    sportName: sportName,
    teamName: data.get('teamName'),
    record: data.get('record')
  }

  // console.log(submission)

  // send post request
  await fetch('http://localhost:8000/teams', {
    method: 'POST',
    body: JSON.stringify(submission),
    headers: { 'Content-Type': 'application/json' }
  })

  // redirect the user
  return redirect('/')

}