import ImageSlider from "./ImageSlider"

const Hero = () => {
  const slides = [
    {
      // url: 'http://localhost:3000/images/hero-1.jpg', 
      url: 'https://master.ddpejpuumlm4i.amplifyapp.com/images/hero-1.jpg',
      title: 'CHAMPIONS', 
      subtitle: 'cham·pi·on | a person who has defeated or surpassed all rivals in a competition',
    },
    {
      //url: 'http://localhost:3000/images/hero-2.jpg', 
      url: 'https://master.ddpejpuumlm4i.amplifyapp.com/images/hero-2.jpg',
      title: 'MEASURE',
      subtitle: 'meas·ure | assess the extent, quality, value, or effect of (something)',
    },
    {
      // url: 'http://localhost:3000/images/hero-3.jpg', 
      url: 'https://master.ddpejpuumlm4i.amplifyapp.com/images/hero-3.jpg',
      title: 'PERFORMANCE',
      subtitle: 'per·for·mance | an action, task, or operation, seen in terms of how successfully it was performed',
    },
    {
      // url: 'http://localhost:3000/images/hero-4.jpg', 
      url: 'https://master.ddpejpuumlm4i.amplifyapp.com/images/hero-4.jpg',
      title: 'STATTRAKR',
      subtitle: "stat·track·er | an innovative tool for tracking your athlete's progress",
    }
  ];
  return (  
    <div>
      <div className="heroSlider">
        <ImageSlider slides={slides} />
      </div>
    </div>
  );
}
 
export default Hero;