import React, { useState } from 'react';
import { Form, useActionData, useNavigate } from 'react-router-dom';
// import ReCAPTCHA from 'react-google-recaptcha';

// Define the isValidEmail function
function isValidEmail(email) {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
};

export default function ContactUs() {
  const [topic, setTopic] = useState('General Information'); // Default topic
  const data = useActionData();
  const showSubject = topic === 'Other';
  // const key = '6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI';
  // const [captchaIsDone, setCaptchaIsDone] = useState(false);

  const navigate = useNavigate();

  // reCAPTCHA function
  // function onChange() {
    // console.log('Changed');
    // setCaptchaIsDone(true);
  // };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData(e.target);

    const email = formData.get('email');

    // Check for control characters in the email
    if (/[^\x20-\x7E]/.test(email)) {
      // Handle invalid email with control character(s) here
      console.error('Invalid email address');
      return;
    }

    // Construct the submission object
    const message = formData.get('message').replace(/[\x00-\x1F\x7F-\x9F]/g, ' '); // Replace control characters with spaces
    const subject = showSubject ? formData.get('subject').replace(/[\x00-\x1F\x7F-\x9F]/g, ' ') : ''; // Replace control characters with spaces
    const submission = {
      Topic: formData.get('topic'),
      SubmissionDate: new Date().toISOString(),
      Email: email,
      Subject: subject, // Use the modified subject
      Message: message, // Use the modified message
      HasBeenReviewed: false,
    };

    // Construct the submission body as a string with escaped double quotes
    const body = JSON.stringify(submission).replace(/"/g, '\\"');

    try {
      const response = await fetch('https://17oblxzoel.execute-api.us-east-2.amazonaws.com/test/contact', {
        method: 'POST',
        body: `{"body":"${body}"}`,
        headers: { 'Content-Type': 'application/json' },
      });

      if (!response.ok) {
        // Handle server errors here
        throw new Error(`Server responded with ${response.status}: ${response.statusText}`);
      }

      // Redirect after successful submission
      navigate('/thank-you'); // Redirect to the "Thank You" page
    } catch (error) {
      // Handle errors in sending the request or server response errors
      console.error('Error:', error);
    }
  };

  return (
    <div className="content">
      <div className="contact">
        <h1>Contact Us</h1>
        <p>Greetings from STATTRAKR!</p> 
        <p>Have you spotted a bug or a stat that doesn't add up? We welcome your insights to enhance our accuracy. And if there's a feature STATTRAKR is missing or a sports fact that's gone awry, let us know. Your suggestions shape our journey of continuous improvement.</p>
        <p>Every piece of feedback, from ideas for new features to a pat on the back, energizes our team and informs our roadmap. Connect with us today – help us tailor STATTRAKR to better suit enthusiasts like you.</p>
        <Form onSubmit={handleSubmit} encType="multipart/form-data">
          <label>
            <span>Topic:</span>
            <select name="topic" value={topic} onChange={(e) => setTopic(e.target.value)} required>
              <option value="General Information">General Information</option>
              <option value="Report a Bug">Report a Bug</option>
              <option value="Product Suggestion">Product Suggestion</option>
              <option value="Accolades">Accolades</option>
              <option value="Other">Other</option>
            </select>
          </label>
          {showSubject && (
            <label>
              <span>Subject:</span>
              <input type="text" name="subject" required={showSubject} />
            </label>
          )}
          <label>
            <span>Your email:</span>
            <input type="email" name="email" required />
          </label>
          <label>
            <span>Your message:</span>
            <textarea name="message" required></textarea>
            {data && data.error && <p style={{color: 'red', fontWeight: 'bold', marginBottom: '10px'}}>{data.error}</p>}
          </label>
          
          {/* {!captchaIsDone && 
          <ReCAPTCHA
            sitekey={ key }
            onChange={onChange}
          />
          } */}

          {/* {captchaIsDone && <button type="submit">Submit</button>} */}
          <button type="submit">Submit</button>

        </Form>
      </div>
    </div>
  )
}